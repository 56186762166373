/*
 * This file contains rewrites of some default-material-styles and overrides of color-variables
 */

// Add the theme-prefix for more specificity
.theme {
  // show label for more dense inputs as well
  .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
    display: inline !important;
  }

  // Override color for chips
  .mat-mdc-standard-chip {
    --mdc-chip-elevated-container-color: var(--primary-main);
    --mdc-chip-label-text-color: var(--text-accent);
    --mdc-chip-with-trailing-icon-trailing-icon-color: var(--text-accent);
  }

  // override color for checkboxes
  .mat-mdc-checkbox.mat-primary {
    --mdc-checkbox-selected-checkmark-color: var(--text-accent);
  }

  // Updating text-color for various mat-buttons
  .mdc-button.mat-primary {
    --mdc-protected-button-label-text-color: var(--text-accent);
    --mdc-filled-button-label-text-color: var(--text-accent);
  }

  // Setting the background for toggle-switches
  .mdc-switch .mdc-switch__track {
    background-color: var(--primary-main);
  }

  .mat-mdc-snack-bar-container {
    &.error-message {
      --mdc-snackbar-container-color: var(--text-error);
    }

    &.success-message {
      --mdc-snackbar-container-color: var(--text-success);
    }

    &.info-message {
      --mdc-snackbar-container-color: var(--text-primary);
    }
  }

  /*
   * Override material table header styles when focused
   */
  [mat-sort-header].cdk-keyboard-focused .mat-sort-header-container,
  [mat-sort-header].cdk-program-focused .mat-sort-header-container {
    border-bottom: 0;

    .mat-sort-header-content {
      border-bottom: 1px solid currentColor;
    }
  }

  // Adjusting mat-label with the class 'radio-group'. This contain a mat-radio-group with their options
  // and need special handling.
  mat-label.radio-group {
    display: flex;
    gap: 8px;

    mat-radio-group {
      display: flex;
      gap: 8px;
    }
  }
}
