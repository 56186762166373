@use '@angular/material' as mat;

@use 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel' as *;
@use 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default' as *;
@use 'material-symbols' as *;
@use 'material' as *;

@use 'shared/variables' as *;

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

/* Common styles for a-tags */
a {
  text-decoration: none;
  color: var(--primary-main);

  &.underlined {
    color: #000000;
    text-decoration: underline;
  }
  *.no-color {
    color: inherit;
  }
  &[href^='tel:'] {
    color: inherit;
  }
}

button.icon-action-button {
  font-size: 19px;
}

/** Styles for form-rows. Maybee this is old CMS-Code an can be replaced? */
[fxlayout~='row']:not(.inline) {
  & > mat-form-field,
  & > app-filter-item > mat-form-field,
  & > app-filter-item > .form-field,
  & > .form-field {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;

    .mat-form-field-wrapper,
    mat-checkbox,
    mat-radio-group {
      margin: 0 10px;
    }

    .mat-radio-button ~ .mat-radio-button {
      margin-left: 16px;
    }
  }

  & > .form-field {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

/** Default-styles for mat-cards */
.mat-mdc-card {
  margin-bottom: 15px;

  .mat-mdc-card-content {
    max-width: 100%;
    overflow-x: auto;
  }

  // fixing paddings for wrongly-/directly-used mat-card-subtitle and mat-card-title
  & > mat-card-subtitle,
  & > mat-card-title {
    padding: 5px 20px 0 20px;
  }

  // styles for the title mat-card
  &.title-card {
    .mat-mdc-card-title {
      display: flex;
      align-items: center;
      padding: 16px;

      h1 {
        flex: 1;
        margin: 0;
        font-size: 0.9em;
        font-weight: 500;
      }

      .actions {
        justify-self: flex-end;

        display: flex;
        align-items: center;
        gap: 8px;
      }
    }

    @media only screen and ($lt-sm) {
      .mat-mdc-card-title {
        flex-direction: column;

        .actions {
          flex-wrap: wrap;
        }
      }
    }
  }

  // Card containing the content / widgets
  &.widget-card {
    .mat-mdc-card-subtitle {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;

      .mat-mdc-card-actions {
        display: flex;
        margin: 0;
        padding: 0;
        gap: 8px;
      }
    }
  }
}

.text-primary {
  color: var(--text-primary);
}
.text-accent {
  color: var(--text-accent);
}
.text-warn {
  color: var(--text-warn);
}
.text-success {
  color: var(--text-success);
}

/** Styles for the image-preview-dialog */
.image-dialog-container {
  .mat-mdc-dialog-container {
    background-color: #ffffff;
    padding: 2px;
    overflow: hidden;
    .mat-mdc-dialog-content {
      position: relative;
      overflow: hidden;
      max-height: 100vh;

      .close-button {
        position: absolute;
        right: 36px; // +24px because of dialog padding
        top: 12px;
      }

      &:hover {
        .close-button {
          opacity: 1;
        }
      }

      img {
        margin-bottom: -5px;
        max-height: 95vh;
        max-width: 95vw;
      }
    }
  }
}

/** Styles for displaying html- / editor-content */
.editor-content {
  p {
    margin: 0;
  }

  // Reproduce custom styles for some of the quill-editor-classes.
  .ql-indent- {
    @for $i from 1 through 8 {
      &#{$i} {
        padding-left: $i * 15px;
      }
    }
  }

  // fixing OL-lists for old quill-editor
  ol li[data-list='bullet'],
  ul li {
    list-style-type: square;
  }
}

// Styles for pages with list-content (e.g. app-grid-list)
.list-page-wrapper {
  .heading {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    h3 {
      // use large flex-grow value, so that the sort select box
      // will stay nearly the same width while still able to grow when wrapped
      flex: 9999 0 auto;
      margin: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      max-width: 100%;
      overflow: hidden;

      mat-icon {
        margin-right: 0.5em;
      }
    }

    app-sort {
      flex: 1 0 200px;
    }
  }

  mat-divider {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .more {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    a {
      background: var(--primary-main);
      color: #fff;

      // Overriding mat-button-styles:
      border-radius: 0;
      padding: 0 32px;
      line-height: 32px;
      text-transform: uppercase;
    }
  }

  .is-hidden-message {
    text-align: center;
  }
}

/** overriding the default styles for the ngx-owl-carousel */
.gallery-embedded {
  .owl-item {
    .image-preview-wrapper {
      // width:height is always 16:9 until 960x540 (maximum width)
      // (80vw - 30px) -> (100% - 2*10% article-container-padding - 2*15px content-wrapper-padding)
      height: calc((80vw - 30px) * (9 / 16));
      max-height: 540px;
      > img {
        object-fit: contain !important;
      }
    }
  }
  .owl-dots {
    margin-left: 32px;
    margin-right: 32px;

    .owl-dot.active > span {
      background-color: var(--primary-main) !important;
    }
  }
}

// Force the text of a checkbox to wrap if added with text-wrap class
mat-checkbox.text-wrap {
  label.mat-checkbox-layout {
    white-space: normal;
  }
}

/** Styling for summary-boxes displaying the summary for some kind of action (e.g. profile-actions) */
.summary-box {
  position: relative;
  display: flex;
  flex-direction: column;
  background: #f6f7f9;

  padding: 20px;
  line-height: 1.5em;
  color: #707070;
  margin: 10px 0;

  h2 {
    margin: 0;
    font-size: 1.1em;
    color: black;
  }

  h3 {
    margin: 0;
    font-size: 1em;
    color: black;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
  }
}
