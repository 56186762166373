/*
 * This file contains SCSS variables that cannot be used as CSS variables and therefore must be imported explicitly (e.g. for media query rules)
 */

/*
 * Breakpoints for media queries from Angular Flex-Layout (https://github.com/angular/flex-layout/blob/master/docs/documentation/Responsive-API.md)
 *
 * xs 	'screen and (max-width: 599px)'
 * sm 	'screen and (min-width: 600px) and (max-width: 959px)'
 * md 	'screen and (min-width: 960px) and (max-width: 1279px)'
 * lg 	'screen and (min-width: 1280px) and (max-width: 1919px)'
 * xl 	'screen and (min-width: 1920px) and (max-width: 5000px)'
 *
 * lt-sm 	'screen and (max-width: 599px)'
 * lt-md 	'screen and (max-width: 959px)'
 * lt-lg 	'screen and (max-width: 1279px)'
 * lt-xl 	'screen and (max-width: 1919px)'
 *
 * gt-xs 	'screen and (min-width: 600px)'
 * gt-sm 	'screen and (min-width: 960px)'
 * gt-md 	'screen and (min-width: 1280px)'
 * gt-lg 	'screen and (min-width: 1920px)'
 */
$lt-sm: 'max-width: 599px';
$lt-md: 'max-width: 959px';
